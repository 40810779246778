import $ from 'jquery'

$(function() {
  setInterval(function() {
    const timer = {}
    timer.web = diffTimes(19)
    if (!timer.web.status) {
      $('.web-time-counter').each(function () { $(this).hide() })
      $('.web-time-end').each(function () { $(this).show() })
    } else {
      $('.web-time-counter').each(function () { $(this).show() })
      $('.web-time-end').each(function () { $(this).hide() })
      $('.web-hour').each(function (i, elm) { elm.innerHTML = timer.web.h })
      $('.web-min').each(function (i, elm) { elm.innerHTML = timer.web.m })
      $('.web-sec').each(function (i, elm) { elm.innerHTML = timer.web.s })
    }
    timer.device = diffTimes(21)
    if (!timer.device.status) {
      $('.device-time-counter').each(function () { $(this).hide() })
      $('.device-time-end').each(function () { $(this).show() })
    } else {
      $('.device-time-counter').each(function () { $(this).show() })
      $('.device-time-end').each(function () { $(this).hide() })
      $('.device-hour').each(function (i, elm) { elm.innerHTML = timer.device.h })
      $('.device-min').each(function (i, elm) { elm.innerHTML = timer.device.m })
      $('.device-sec').each(function (i, elm) { elm.innerHTML = timer.device.s })
    }
  }, 1000)

  function diffTimes(limitHour, limitMin, limitSec) {
    const timers = {}
    limitHour = limitHour || 0
    limitMin = limitMin || 0
    limitSec = limitSec || 0
    const dt = new Date()
    const limit = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      limitHour,
      limitMin,
      limitSec
    )
    const diff = limit.getTime() - dt.getTime()
    timers.status = diff > 0
    timers.h = `00${Math.floor(diff / 1000 / 60 / 60)}`.slice(-2)
    timers.m = `00${Math.floor(diff / 1000 / 60) % 60}`.slice(-2)
    timers.s = `00${Math.floor(diff / 1000) % 60}`.slice(-2)
    return timers
  }
})
